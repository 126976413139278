
.card-base-content {
  position: relative;
  overflow: hidden;
  background-color: var(--w-color-neutral-10);
  padding-block: var(--w-size-35);
  padding-inline: var(--w-size-35);
  margin-top: var(--w-size-35);
  border-radius: .25rem;

  &.transparent {
    background-color: transparent;
  }

  &.tight {
    padding-block: 0;
    padding-inline: 0;
    margin-top: 0;
  }
}

.card-base-linear {
  position: absolute;
  top: 0px;
  left: 0px;
}
