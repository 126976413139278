
.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: var(--w-size-10);
}

/* Shimmer effect */
.skeleton-line {
  height: var(--w-size-35);
  background: linear-gradient(to right,
      var(--w-color-neutral-30) 8%,
      var(--w-color-neutral-20) 18%,
      var(--w-color-neutral-30) 33%);
  background-size: 800px 100%;
  animation: shimmer 7s infinite linear;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
    background-position: -800px 0;
  }

  100% {
    background-position: 800px 0;
  }
}
