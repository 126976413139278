
.button-primary {
  font-weight: var(--w-font-weight-50);
}

.button-primary:focus,
.button-primary:hover {
  box-shadow: var(--w-shadow-primary);
}

.button-primary:disabled {
  background-color: var(--w-transparency-primary);
  border: none !important;
  box-shadow: none !important;
}
