
.detail {
  font-weight: var(--w-font-weight-10);
  padding: 0;
  list-style-position: inside;
}

.message-title {
  font-size: var(--w-font-size-45);
  font-weight: var(--w-font-weight-30);
}
