
.action-icon {
  color: var(--text-color);
  background-color: var(--bg-color);
  filter: none;
  border: none;
}

.action-icon:focus {
  color: var(--text-color);
  background-color: var(--w-color-neutral-10);
  box-shadow: var(--w-shadow-primary);
}

.action-icon:hover {
  color: var(--w-color-primary-30);
  background-color: var(--w-color-neutral-10);
  box-shadow: var(--w-shadow-primary);
}

.action-icon:active {
  color: var(--w-color-primary-30);
  background-color: var(--w-color-neutral-10);
  filter: none;
}

.action-icon:disabled {
  color: var(--w-color-neutral-40);
  background-color: var(--bg-color);
  filter: none;
  pointer-events: none;
}
