
.button-tertiary {
  font-weight: var(--w-font-weight-50);
  color: var(--w-color-primary-30);
  background-color: var(--w-color-neutral-10);
  border: var(--w-border-size-10) solid transparent;
}

.button-tertiary:focus,
.button-tertiary:hover,
.button-tertiary:active {
  color: var(--w-color-primary-30) !important;
  background-color: var(--w-color-neutral-10) !important;
  border: var(--w-border-size-10) solid var(--w-color-primary-30) !important;
}

.button-tertiary:disabled {
  color: var(--w-transparency-primary) !important;
  background-color: var(--w-color-neutral-10) !important;
  border: var(--w-border-size-10) solid var(--w-color-neutral-10) !important;
}
