
.notifications-global__toasts {
  position: fixed;
  top: var(--w-size-30);
  right: var(--w-size-30);
  width: var(--w-size-70);

  display: flex;
  flex-direction: column;
  gap: var(--w-size-20);
  z-index: 9999;
}
