
.badge-wrapper {
  height: min-content;
  position: relative;
  display: flex;
  align-items: center;
}

.icon-badge {
  --inner-size: var(--w-size-20);
  --size: calc(var(--inner-size) * 1.5);
  --offset: calc(var(--size) / -3.5);
  position: absolute;
  font-size: var(--inner-size);
  height: var(--size);
  width: var(--size);
  top: var(--offset);
  right: var(--offset);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--w-color-warning-30);
  color: var(--w-color-neutral-10);
  font-weight: var(--w-font-weight-50);
}
