
.input-filter {
  padding-inline: var(--w-size-30);
  display: flex;
  gap: var(--w-size-10);
}

.input-filter__input {
  width: 100%;
  padding-inline: var(--w-size-20);
  padding-block: var(--w-size-10);

  border: var(--w-border-neutral);
  outline: none;
  color: var(--w-plain-text-color);

  &:focus {
    box-shadow: var(--w-border-shadow-neutral);
  }
}
