
/* a localização de texto do tema do Quill é (estranhamente) feita por meio de CSS */
.rich-text-wrapper::v-deep .ql-tooltip.ql-editing::before {
  content: "Digite o link:";
}

.rich-text-wrapper::v-deep .ql-tooltip::before {
  content: "Visitar URL:";
}

.rich-text-wrapper::v-deep .ql-tooltip.ql-editing a.ql-action::after {
  content: "Salvar";
}

.rich-text-wrapper::v-deep .ql-tooltip a.ql-action::after {
  content: "Editar";
}

.rich-text-wrapper::v-deep .ql-tooltip a.ql-remove::before {
  content: "Remover";
}

.rich-text-wrapper {
  border: var(--w-border-neutral);
}

.rich-text-wrapper.valid {
  border-color: var(--w-border-color-success);
}

.rich-text-wrapper.invalid {
  border-color: var(--w-border-color-danger);
}

.rich-text-wrapper::v-deep .ql-toolbar {
  border: none;
  background-color: var(--w-color-neutral-20);
}

.rich-text-wrapper::v-deep .ql-container {
  border: none;
  height: var(--w-size-70);
}

.disabled::v-deep .ql-toolbar {
  border-bottom: 0;
  padding: 0;
}

.disabled::v-deep .ql-formats {
  display: none;
}
