
.input-lookup {
  /*
    facilita construir responsividade em composições com o componente.
    especialmente dentro de um inputGroup como acontece no uso de
    searchText nas listagens da aplicação.
  */
  width: 100%;
}

.wrapper {
  position: relative;
  z-index: 99;
}

.lookup-menu {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0;
  margin-top: var(--w-size-10);
  padding: 0;
  border: var(--w-border-size-10) solid var(--w-color-neutral-20);
  box-shadow: var(--w-shadow-primary);
  background-color: var(--w-color-neutral-10);
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

.lookup-item {
  list-style-type: none;
  cursor: pointer;
  padding: 0 var(--w-size-30);
}

.active {
  background-color: var(--w-color-info-30);
  color: var(--w-color-neutral-10);
}

.wrapper-wrapper {
  display: flex;
}
