
.dropdown-surface {
  position: fixed;
  margin: 0;
  padding: 0;
  padding-block: var(--w-size-20);
  top: calc(var(--anchor-top) + var(--anchor-height) + 1px);
  left: var(--anchor-left);
  width: var(--anchor-width);

  border: var(--w-border-neutral);
  box-shadow: var(--w-shadow-neutral);
}

.dropdown-surface__scrollable {
  max-height: calc(var(--input-lookup-font-size) * 2 * var(--rows));
  overflow-y: auto;
  overflow-x: hidden;
}
