
/*
    as tags !important estão sendo usadas para conseguir sobrescrever os estilos
    do framework bootstrap-vue.
  */
.read-only {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0 !important;
  color: var(--w-color-neutral-50) !important;
  background: none !important;
  opacity: 1;
}
