
.button-secondary {
  font-weight: var(--w-font-weight-50);
  color: var(--w-color-primary-30);
  background-color: var(--w-color-neutral-10);
  border: var(--w-border-size-10) solid var(--w-color-neutral-20);
}

.button-secondary:focus,
.button-secondary:active {
  color: var(--w-color-primary-30) !important;
  background-color: var(--w-color-neutral-10) !important;
  border: var(--w-border-size-10) solid var(--w-color-primary-30) !important;
}

.button-secondary:hover {
  color: var(--w-color-primary-30);
  background-color: var(--w-color-neutral-10);
  border: var(--w-border-size-10) solid var(--w-color-neutral-20);
  box-shadow: var(--w-shadow-primary);
}

.button-secondary:disabled {
  color: var(--w-transparency-primary) !important;
  background-color: var(--w-color-neutral-10) !important;
  border: var(--w-border-size-10) solid var(--w-color-neutral-20) !important;
  box-shadow: none !important;
}
