
.spinner-base {
  width: var(--size);
  height: var(--size);

  border: var(--w-border-size-20) solid var(--w-color-neutral-20);
  border-radius: 50%;
  border-left-color: var(--w-color-info-30);

  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
