
.feedback-message {
  font-size: 1em;
  color: gray;
  font-weight: bold;
  margin-right: auto;
  padding-right: 30px;
  word-break: break-word;
}

.action-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;

}

@media (max-width: 768px) {
  .feedback-message {
    margin-bottom: 10px;
    padding-right: 0;
  }

  .action-buttons {
    justify-content: center;
    gap: 5px;
  }

  .action-buttons button {
    flex: 1 1 auto;
    max-width: 100%;
  }

  .action-buttons {
    flex-direction: column;
    align-items: stretch;
  }
}
