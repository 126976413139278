
a {
  color: var(--w-color-neutral-10);
  border-left: var(--w-border-size-30) solid transparent;
}

a.router-link-exact-active {
  color: var(--w-color-success-50);
  border-left: var(--w-border-size-30) solid var(--w-color-success-50);
}

a:hover {
  border-left: var(--w-border-size-30) solid var(--w-color-primary-40);
  color: var(--w-color-primary-40) !important;
  background-color: var(--w-color-neutral-10);
}

a:hover::v-deep svg {
  fill: var(--w-color-primary-40) !important;
}
