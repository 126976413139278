
.zoom-label {
  display: none;
}

@media (min-width: 576px) {
  .zoom-label {
    display: revert;
  }
}
