
.tab-accordion-header {
  height: var(--w-size-50);
  font-size: var(--w-size-30);
  text-align: left;
  border-color: transparent;
  border-bottom-color: var(--w-color-neutral-20);
}

.collapsed .when-open,
.not-collapsed .when-closed {
  display: none;
}
