/* em 01/10/2021 e 21/11/2022 em relação ao tema neoid no site http://serprodesign.estaleiro.serpro, não são explicitados tokens de design quanto à cores do design system. muitas das cores abaixo são inferidas por investigação no site ou adaptadas do tema corporativo que acompanha uma paleta de cores consistentemente aplicada nos componentes. */

:root {
  font-family: "Open Sans", sans-serif;

  --w-font-size-10: 10px;
  --w-font-size-15: 12px;
  --w-font-size-20: 14px;
  --w-font-size-30: 16px;
  --w-font-size-35: 20px;
  --w-font-size-40: 24px;
  --w-font-size-45: 28px;
  --w-font-size-50: 36px;
  --w-font-size-55: 40px;
  --w-font-size-60: 44px;
  --w-font-size-65: 56px;
  --w-font-size-70: 64px;
  --w-font-size-80: 128px;
  --w-font-size-85: 200px;
  --w-font-size-90: 256px;

  --w-font-weight-10: 300;
  --w-font-weight-20: 400;
  --w-font-weight-30: 500;
  --w-font-weight-40: 600;
  --w-font-weight-50: 700;

  --w-plain-text-color: var(--w-color-neutral-50);

  --w-size-00: 1px;
  --w-size-05: 2px;
  --w-size-10: 4px;
  --w-size-20: 8px;
  --w-size-30: 16px;
  --w-size-35: 24px;
  --w-size-40: 32px;
  --w-size-45: 48px;
  --w-size-50: 64px;
  --w-size-60: 128px;
  --w-size-70: 256px;
  --w-size-73: 384px;
  --w-size-77: 448px;
  --w-size-80: 512px;
  --w-size-83: 576px;
  --w-size-87: 768px;
  --w-size-90: 1024px;

  --w-tcy: 37%, 37%;
  --w-transparency-primary: hsla(var(--w-color-primary), var(--w-tcy));
  --w-transparency-neutral: hsla(var(--w-color-neutral), var(--w-tcy));
  --w-transparency-info: hsla(var(--w-color-info), var(--w-tcy));
  --w-transparency-success: hsla(var(--w-color-success), var(--w-tcy));
  --w-transparency-warning: hsla(var(--w-color-warning), var(--w-tcy));
  --w-transparency-danger: hsla(var(--w-color-danger), var(--w-tcy));

  --w-shadow-geometry: 0 var(--w-size-20) var(--w-size-20);
  --w-shadow-primary: var(--w-shadow-geometry) var(--w-transparency-primary);
  --w-shadow-neutral: var(--w-shadow-geometry) var(--w-transparency-neutral);
  --w-shadow-info: var(--w-shadow-geometry) var(--w-transparency-info);
  --w-shadow-success: var(--w-shadow-geometry) var(--w-transparency-success);
  --w-shadow-warning: var(--w-shadow-geometry) var(--w-transparency-warning);
  --w-shadow-danger: var(--w-shadow-geometry) var(--w-transparency-danger);

  --w-color-primary: 258, 58%;
  --w-color-primary-10: hsl(var(--w-color-primary), 90%);
  --w-color-primary-20: hsl(var(--w-color-primary), 77%);
  --w-color-primary-30: hsl(var(--w-color-primary), 63%);
  --w-color-primary-40: hsl(var(--w-color-primary), 50%);
  --w-color-primary-50: hsl(var(--w-color-primary), 37%);
  --w-color-primary-60: hsl(var(--w-color-primary), 23%);
  --w-color-primary-70: hsl(var(--w-color-primary), 15%);
  --w-color-primary-on-light: var(--w-color-primary-60);

  --w-color-neutral: 240, 14%;
  --w-color-neutral-10: hsl(var(--w-color-neutral), 100%);
  /* brilho do neutral-20 é um ajuste fino após vários testes manuais com o background do neosigner */
  --w-color-neutral-20: hsl(var(--w-color-neutral), 93%);
  /* brilho do neutral-30 é um ajuste fino após vários testes manuais com as bordas dos controles de formulário */
  --w-color-neutral-30: hsl(var(--w-color-neutral), 80%);
  --w-color-neutral-40: hsl(var(--w-color-neutral), 50%);
  --w-color-neutral-50: hsl(var(--w-color-neutral), 37%);
  --w-color-neutral-60: hsl(var(--w-color-neutral), 23%);
  --w-color-neutral-70: hsl(var(--w-color-neutral), 0%);
  --w-color-neutral-on-light: var(--w-color-neutral-60);

  --w-color-info: 208, 100%;
  --w-color-info-10: hsl(var(--w-color-info), 90%);
  --w-color-info-20: hsl(var(--w-color-info), 77%);
  --w-color-info-30: hsl(var(--w-color-info), 63%);
  --w-color-info-40: hsl(var(--w-color-info), 50%);
  --w-color-info-50: hsl(var(--w-color-info), 37%);
  --w-color-info-60: hsl(var(--w-color-info), 23%);
  --w-color-info-70: hsl(var(--w-color-info), 10%);
  --w-color-info-on-light: var(--w-color-info-50);

  --w-color-success: 169, 89%;
  --w-color-success-10: hsl(var(--w-color-success), 90%);
  --w-color-success-20: hsl(var(--w-color-success), 77%);
  --w-color-success-30: hsl(var(--w-color-success), 63%);
  --w-color-success-40: hsl(var(--w-color-success), 50%);
  --w-color-success-50: hsl(var(--w-color-success), 37%);
  --w-color-success-60: hsl(var(--w-color-success), 23%);
  --w-color-success-70: hsl(var(--w-color-success), 10%);
  --w-color-success-on-light: var(--w-color-success-50);

  --w-color-warning: 7, 63%;
  --w-color-warning-10: hsl(var(--w-color-warning), 90%);
  --w-color-warning-20: hsl(var(--w-color-warning), 77%);
  --w-color-warning-30: hsl(var(--w-color-warning), 63%);
  --w-color-warning-40: hsl(var(--w-color-warning), 50%);
  --w-color-warning-50: hsl(var(--w-color-warning), 37%);
  --w-color-warning-60: hsl(var(--w-color-warning), 23%);
  --w-color-warning-70: hsl(var(--w-color-warning), 10%);
  --w-color-warning-on-light: var(--w-color-warning-50);

  --w-color-danger: 329, 59%;
  --w-color-danger-10: hsl(var(--w-color-danger), 90%);
  --w-color-danger-20: hsl(var(--w-color-danger), 77%);
  --w-color-danger-30: hsl(var(--w-color-danger), 63%);
  --w-color-danger-40: hsl(var(--w-color-danger), 50%);
  --w-color-danger-50: hsl(var(--w-color-danger), 37%);
  --w-color-danger-60: hsl(var(--w-color-danger), 23%);
  --w-color-danger-70: hsl(var(--w-color-danger), 10%);
  --w-color-danger-on-light: var(--w-color-danger-50);

  --w-border-size-10: 1px;
  --w-border-size-20: 4px;
  --w-border-size-30: 8px;

  --w-border-color-neutral: var(--w-color-neutral-30);
  --w-border-color-success: var(--w-color-success-40);
  --w-border-color-danger: var(--w-color-danger-40);

  --w-border-neutral: var(--w-border-size-10) solid var(--w-border-color-neutral);

  --w-border-shadow-inset-geometry: inset 0 0 0 var(--w-size-00);
  --w-border-shadow-outset-geometry: 0 0 0 var(--w-size-05);
  /* definidos para serem usadas em propriedades box-shadow a fim de criar efeito de transparência na borda em estados de foco, por exemplo */
  --w-border-shadow-neutral: var(--w-border-shadow-inset-geometry) var(--w-border-color-neutral), var(--w-border-shadow-outset-geometry) var(--w-transparency-primary);
  --w-border-shadow-success: var(--w-border-shadow-inset-geometry) var(--w-border-color-neutral), var(--w-border-shadow-outset-geometry) var(--w-transparency-success);
  --w-border-shadow-danger: var(--w-border-shadow-inset-geometry) var(--w-border-color-neutral), var(--w-border-shadow-outset-geometry) var(--w-transparency-danger);
}