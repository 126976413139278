
/*
  hacks para aproximar o posicionamento das imagens ao que é observado com os
  demais ícones em botões. usado inicialmente com os ícones do serpro-id e
  assinador desktop serpro.
*/
.image-icon {
  margin-right: var(--w-size-10);
  margin-top: calc(-1 * var(--w-size-10));
}
