
.container {
  max-width: var(--w-size-70);
}
.code-input-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.char-input {
  /*
    esse tamanho de fonte é especialmente importante no ios.
    foi verificado (em um iPhone 6s, iOS 14.4, Safari 14) que
    fontes maiores fazem o texto ser ocultado pelo input.
  */
  font-size: var(--w-font-size-30);
  /* harmoniza a altura do input ao tamanho reduzido da fonte */
  line-height: var(--w-size-40);
  width: var(--w-size-35);
  border-top: none;
  border-left: none;
  border-right: none;
  border-width: 4px;
  border-color: var(--w-color-primary-on-light);
  background-color: var(--w-color-neutral-10);
  text-align: center;
}

@media (min-width: 576px) {
  .char-input {
    font-size: var(--w-font-size-45);
    line-height: revert;
  }
}

.char-input:focus-visible {
  outline: none;
}
