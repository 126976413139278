
.notifications-toast {
  position: relative;
  overflow: hidden;
  padding: var(--w-size-20);

  background-color: var(--w-color-neutral-10);
  border: var(--w-border-neutral);

  font-size: var(--w-font-size-20);

  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: var(--w-size-30);
  align-items: center;
}

.notifications-toast__icon-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications-toast__message-column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.notifications-toast__button-column {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
