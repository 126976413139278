
.hero-page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.hero-page-background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-position: center;

  @media (min-width: 576px) {
    & {
      display: revert;
    }
  }
}

.hero-page-content {
  position: relative;
}
