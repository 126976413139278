
.logo-full {
  display: var(--full-xs) !important;
}

.logo-min {
  display: var(--min-xs) !important;
}

@media (min-width: 576px) {
  .logo-full {
    display: flex !important;
  }
  .logo-min {
    display: none !important;
  }
}
