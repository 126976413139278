
.content {
  display: flex;
  align-items: center;
}

.current .text {
  color: var(--w-color-primary-30);
}

.dot {
  height: var(--w-size-20);
  width: var(--w-size-20);
  background-color: var(--w-color-neutral-10);
  border-radius: 50%;
  display: inline-block;
}

.current .dot {
  background-color: var(--w-color-primary-30);
}
