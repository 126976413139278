
.control-set {
  margin: 0;
  border: var(--w-border-neutral);
  padding-block: var(--w-size-20);
  padding-inline: var(--w-size-30);
}

.control-set.divider {
  border-inline-color: transparent;
  border-block-end-color: transparent;
}
