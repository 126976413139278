
.actions-bar {
  display: flex;
  flex-direction: column-reverse;
}

.actions-bar > :not(:last-child) {
  margin-top: var(--w-size-30);
}

.actions-end {
  margin-top: var(--w-size-30);
}

/*
    a media query abaixo não funcionaria pq variáveis não são suportadas em media
    queries, daí a necessidade de configurar literalmente a dimensão do breakpoint.

    @media (min-width: var(--breakpoint-lg)) { ...
  */
@media (min-width: 768px) {
  .actions-bar {
    flex-direction: row;
    justify-content: end;
  }

  .actions-bar > :not(:last-child) {
    margin-top: 0;
    margin-right: var(--w-size-20);
  }

  .actions-end {
    margin-top: 0;
  }
}
