
.search-text {
  position: relative;
}

.search-text-app-icon-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: var(--w-size-20);
  font-size: var(--w-font-size-35);
}
