
.list-base-table::v-deep th,
.list-base-table::v-deep .b-table-stacked-md tr [data-label]::before {
  color: var(--w-color-primary-30);
  font-size: var(--w-font-size-20);
  font-weight: var(--w-font-weight-20) !important;
}

.list-base-table::v-deep .b-table-details td {
  overflow-y: auto;
}

.list-base-table::v-deep .has-default-action:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .list-base-table::v-deep .b-table-stacked-md td {
    display: flex !important;
    align-items: center;
  }
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.items-per-page-selector {
  display: flex;
  align-items: center;
}

.items-per-page-selector label {
  margin-right: 8px;
  font-size: 14px;
}

.items-per-page-selector select {
  padding: 4px 8px;
  font-size: 14px;
}

.total-items {
  font-size: 14px;
  margin: 0;
}
