
.display-value {
  --state-color: var(--w-border-color-neutral);
  --state-shadow: var(--w-border-shadow-neutral);
}

.display-value.valid {
  --state-color: var(--w-border-color-success);
  --state-shadow: var(--w-border-shadow-success);
}

.display-value.invalid {
  --state-color: var(--w-border-color-danger);
  --state-shadow: var(--w-border-shadow-danger);
}

.display-value {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(var(--input-lookup-font-size) * 2.5);
  padding-inline-start: var(--w-size-30);
  padding-inline-end: var(--w-size-40);
  padding-block: var(--w-size-20);

  justify-content: space-between;

  cursor: pointer;

  border: var(--w-border-neutral);
  background: transparent;

  text-align: left;
  color: inherit;
}

.display-value:focus {
  box-shadow: var(--state-shadow);
}

.display-value::after {
  content: "▼";
  position: absolute;
  right: var(--w-size-20);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.display-value.valid::after,
.display-value.invalid::after {
  color: var(--state-color)
}

.display-value.disabled {
  background-color: var(--w-color-neutral-20);
}
