// sobrescrevendo estilos da bootstrap-vue com valores correspondentes aos temas da aplicação. sempre que possível fazendo referência as variáveis css da própria aplicação.

$font-family-sans-serif: "Open Sans", sans-serif;

$spacer: 16px;
$spacers: (
  0: 0,
  1: var(--w-size-10),
  2: var(--w-size-20),
  3: var(--w-size-30),
  4: var(--w-size-35),
  5: var(--w-size-40),
  6: var(--w-size-45),
  7: var(--w-size-50),
  8: var(--w-size-60),
  9: var(--w-size-70),
  10: var(--w-size-73),
  11: var(--w-size-77),
  12: var(--w-size-83),
  13: var(--w-size-87),
);

$enable-rounded: false;

$btn-focus-width: 0;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;
$btn-disabled-opacity: 1;

$w-color-primary-50: hsl(258, 58%, 39%);
$primary: $w-color-primary-50;

$w-color-neutral-50: hsl(240, 14%, 39%);
$secondary: $w-color-neutral-50;
$input-color: $w-color-neutral-50;
$input-plaintext-color: $w-color-neutral-50;
$body-color: $w-color-neutral-50;

$w-color-neutral-10: hsl(240, 14%, 100%);
$light: $w-color-neutral-10;

$w-color-neutral-40: hsl(240, 14%, 50%);
$dark: $w-color-neutral-40;

$w-color-info-30: hsl(218, 100%, 62%);
$info: $w-color-info-30;

$w-color-danger-50: hsl(329, 59%, 39%);
$danger: $w-color-danger-50;

$w-color-warning-30: hsl(7, 63%, 62%);
$warning: $w-color-warning-30;

$w-color-success-50: hsl(169, 89%, 39%);
$success: $w-color-success-50;

// sobrescrevendo bordas dos controles com tokens to tema da aplicação. só foi possível fazer isso com !important.
.form-control {
  border: var(--w-border-neutral) !important; 
}

// aqui trocando a cor dos ícones da tabela da bootstrap-vue. nesse caso a única alternativa identificada foi incluir manualmente a cor correspondente ao token "--w-color-neutral-50" na url string.

$b-table-sort-icon-bg-not-sorted: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'><path fill='#565671' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/></svg>") !default;
$b-table-sort-icon-bg-ascending: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'><path fill='#565671' d='M51 1l25 23 24 22H1l25-22z'/><path fill='#565671' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/></svg>") !default;
$b-table-sort-icon-bg-descending: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'><path fill='#565671' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/><path fill='#565671' d='M51 101l25-23 24-22H1l25 22z'/></svg>") !default;
