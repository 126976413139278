
.shifter {
  color: var(--mobile);
}

@media (min-width: 576px) {
  .shifter {
    color: var(--desktop);
  }
}
